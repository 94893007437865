h1 {
  margin-top: 60px;
}

h3 {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 15px;
}

.headerResume {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  align-items: center;
}

.contentHeader {
  width: 80%;
}

.h1Name {
  text-transform: uppercase;
}

.professionalResume {
  display: flex;
  flex-direction: column;
  margin-left: 94px;
}

.dividerRight {
  margin-top: 25px;
  margin-bottom: 25px;
}
