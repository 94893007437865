.ccontainer .ql-editor {
  width: 8.5in;
  min-height: 11in;
  padding: 1in;
  margin: 2rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
}

.ccontainer .ql-container.ql-snow {
  border: none;
  display: flex;
  justify-content: center;
}

.ccontainer .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f3f3f3;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

@page {
  margin: 1in;
}

@media print {
  body {
    background: none;
  }

  .ccontainer .ql-editor {
    width: 6.5in;
    height: 9in;
    padding: 0;
    margin: 0;
    box-shadow: none;
    align-self: flex-start;
  }

  .ccontainer .ql-toolbar.ql-snow {
    display: none;
  }
}
