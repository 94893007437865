.Paper {
  padding: 20px;
  margin-bottom: 15px;
}

.Filters {
  background-color: #f7f9fa;
}

.Title {
  margin-top: 0;
  position: relative;
  margin-bottom: 0;
}

.Root {
  margin-left: 0;
  margin-right: 0;
}

.Root > span {
  padding: 3px 3px 3px 3px;
  margin: 3px 3px 3px 0;
}

.Accordion {
  box-shadow: none;
  background-color: transparent;
}

.Margin0 {
  margin: 0;
}

.Accordion:before {
  content: none;
}

.AccordionSummary {
  padding: 0;
  min-height: 48px !important;
}

.ExpandIcon {
  padding-right: 0;
  padding-left: 0;
  right: 0;
}

.SummaryContent {
  margin: 12px 0 0 0 !important;
}

.AccordionContent {
  max-height: 200px;
  overflow: auto;
  /* overflow-y: scroll; */
  padding: 5px 15px 5px 0;
}

.List {
  height: max-content;
  width: 100%;
}

.InputRange {
  text-align: left;
  margin-bottom: 20px;
}

.Loading {
  margin-bottom: 0;
  min-height: calc(100vh - calc(3rem + 124px));
  display: flex;
  justify-content: center;
  align-items: center;
}
