button{
    outline: none !important;
}
a:hover{
color: inherit !important;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .PhoneInputInput{
      border: none !important;
      outline:none !important;
  }
  .public-DraftEditor-content{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }
  #pdf div p{
    font-size: 10px !important;
  }
  #pdf div h2{
    font-size: 30px !important;
  }
  .RichTextEditor__root___2QXK-{
    height:1132px;
  }